<template>
  <zy-layout>
    <!-- 左边布局 -->
    <zy-layout-column animation="left">
      <zy-title>今日考勤信息</zy-title>
      <row-label :list="todayAttendanceList"></row-label>
      <zy-scroll-table
        :data="todayAttendanceInfo.Attendance"
        class="jxyh-margin"
      >
        <zy-scroll-table-column
          label="考勤时间"
          prop="atteTime"
          width="180px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="进/出场"
          prop="type"
          width="80px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="姓名"
          prop="workName"
          width="80px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="班组名称"
          prop="teamName"
          width="120px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="考勤设备"
          prop="position"
          width="100px"
        ></zy-scroll-table-column>
      </zy-scroll-table>
      <zy-title class="jxyh-margin">
        出勤人数趋势
        <template slot="more">
          <zy-select
            :data="weakList"
            v-model="attendanceTrendType"
            @selectType="getAttendanceTrend"
          >
            所属期间
          </zy-select>
        </template>
      </zy-title>
      <zy-chart-bar
        :data="attendanceTrendBar.barList"
        :axis="attendanceTrendBar.barX"
        :color="['#0360F5', '#63E881']"
      ></zy-chart-bar>
      <zy-title> 班组评分排名 </zy-title>
      <zy-scroll-table :data="teamScoreList">
        <zy-scroll-table-column
          label="排名"
          type="index"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="班组名称"
          prop="teamName"
          width="220px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="班组负责人"
          prop="leaderName"
          width="100px"
        ></zy-scroll-table-column>
        <zy-scroll-table-column
          label="班组评分"
          prop="evalScore"
          align="right"
        ></zy-scroll-table-column>
      </zy-scroll-table>
    </zy-layout-column>
    <!-- 中间布局 -->
    <zy-layout-column animation="center">
      <zy-title>在册人员情况</zy-title>
      <card :list="registeredInfo"></card>
      <zy-title class="jxyh-margin">安全培训</zy-title>
      <zy-canvas-ring
        :size="150"
        :height="180"
        :list="safetyTrainingData"
      >
        <div class="zy-canvas-ring-number">{{ safetyTrainingInfo.workerNums }}</div>
        <div class="zy-canvas-ring-des">在册人员(人)</div>
      </zy-canvas-ring>
      <div class="safe-table">
        <zy-scroll-table
          :height="340"
          :data="personnelTotalList"
        >
          <zy-scroll-table-column
            label="姓名"
            prop="workerName"
            width="256px"
          ></zy-scroll-table-column>
          <zy-scroll-table-column
            label="所属班组"
            prop="teamName"
            width="200px"
          ></zy-scroll-table-column>
          <zy-scroll-table-column
            label="是否完成培训"
            prop="safetyTraining"
            width="200px"
          ></zy-scroll-table-column>
          <zy-scroll-table-column
            label="入场时间"
            prop="joinCompanyDate"
            width="92px"
            align="right"
          ></zy-scroll-table-column>
        </zy-scroll-table>
      </div>
    </zy-layout-column>
    <!-- 右边布局 -->
    <zy-layout-column animation="right">
      <zy-title>工种分布</zy-title>
      <zy-pie-table :list="workTypeList">
        <div class="zy-pie-table-number">{{ workTypeTotal }}</div>
        <div class="zy-pie-table-des">总人数</div>
        <template slot="table">
          <el-table-column
            label="工种类型"
            prop="name"
          ></el-table-column>
          <el-table-column
            label="工种人数"
            prop="value"
            align="right"
          ></el-table-column>
          <el-table-column
            label="占比"
            prop="percentage"
            align="right"
          >
          </el-table-column>
        </template>
      </zy-pie-table>
      <zy-title class="jxyh-margin">性别分布、工人年龄</zy-title>
      <sex-and-age></sex-and-age>
    </zy-layout-column>
  </zy-layout>
</template>

<script>
import components from "@/view/jxyh/components/index.js"
import RowLabel from "./components/rowLabel.vue"
import Card from "./components/card.vue"
import SexAndAge from "./components/sexAndAge.vue"
import { todayAttendance, registeredNumber, attendanceTrend, teamScore, safetyTraining, workType, totalList } from "@/jxyh/api/labor"
export default {
  ...components(["ZyLayout", "ZyLayoutColumn", "ZyTitle", "ZyScrollTable", "ZyScrollTableColumn", "ZySelect", "ZyChartBar", "ZyCanvasRing", "ZyPieTable"], { RowLabel, Card, SexAndAge }),
  data() {
    return {
      todayAttendanceInfo: {},
      registeredInfo: [],
      attendanceTrendInfo: [],
      attendanceTrendType: 7,
      teamScoreList: [],
      safetyTrainingInfo: [],
      workTypeList: [],
      personnelTotalList: [],
      scoreList: [],
      weakList: [
        {
          label: "7天",
          id: 7,
        },
        {
          label: "30天",
          id: 30,
        },
      ],
      barX: [],
      barList: [],
    }
  },
  computed: {
    workTypeTotal() {
      let total = 0
      for (let i = 0; i < this.workTypeList.length; i++) {
        total += Number(this.workTypeList[i].value)
      }
      return total
    },
    safetyTrainingData() {
      const completeNum = this.safetyTrainingInfo.completeNum || 0
      const incompleteNum = this.safetyTrainingInfo.incompleteNum || 0
      const total = completeNum + incompleteNum
      return [
        {
          name: "完成培训",
          value: completeNum,
          rate: (completeNum / total || 0) * 100,
          unit: "人",
        },
        {
          name: "待培训",
          value: incompleteNum,
          rate: (incompleteNum / total || 0) * 100,
          unit: "人",
        },
      ]
    },
    attendanceTrendBar() {
      const title = []
      const list = [
        {
          name: "劳务工人",
          data: [],
        },
        {
          name: "项目人员",
          data: [],
        },
      ]
      for (let i = 0; i < this.attendanceTrendInfo.length; i++) {
        const trend = this.attendanceTrendInfo[i]
        title.push(trend[0])
        const lwry = trend[1] || 0
        const xmry = trend[2] || 0
        list[0].data.push(lwry)
        list[1].data.push(xmry)
      }
      return {
        barList: list,
        barX: title,
      }
    },
    todayAttendanceList() {
      return [
        {
          icon: require("@/view/jxyh/assets/icons/icon_ry.png"),
          name: "劳务工人出勤数",
          value: this.todayAttendanceInfo.totalBuilderNumber || 0,
          unit: "人",
        },
        {
          icon: require("@/view/jxyh/assets/icons/icon_cqzrs.png"),
          name: "当前在场工人",
          value: this.todayAttendanceInfo.workerNums || 0,
          unit: "人",
        },
        {
          icon: require("@/view/jxyh/assets/icons/icon_attendance.png"),
          name: "项目人员出勤数",
          value: this.todayAttendanceInfo.totalStaffNumber || 0,
          unit: "人",
        },
        {
          icon: require("@/view/jxyh/assets/icons/icon_staff.png"),
          name: "当前在场人员",
          value: this.todayAttendanceInfo.totalNum || 0,
          unit: "人",
        },
      ]
    },
  },
  created() {
    this.getTodayAttendance()
    this.getRegisteredNumber()
    this.getAttendanceTrend()
    this.getTeamScore()
    this.getSafetyTraining()
    this.getWorkType()
    this.getTotalList()
  },
  methods: {
    //注册人员情况
    getRegisteredNumber() {
      registeredNumber()
        .then((res) => {
          this.registeredInfo = res.data || []
        })
        .catch((_) => {})
    },
    //今日考勤信息
    getTodayAttendance() {
      todayAttendance()
        .then((res) => {
          this.todayAttendanceInfo = res.data || {}
        })
        .catch((_) => {})
    },
    //出勤人数趋势
    getAttendanceTrend() {
      attendanceTrend(this.attendanceTrendType).then((res) => {
        this.attendanceTrendInfo = res.data.columnData || []
      })
    },
    //班组评分排名
    getTeamScore() {
      teamScore()
        .then((res) => {
          this.teamScoreList = res.data || []
        })
        .catch((_) => {})
    },
    //安全培训
    getSafetyTraining() {
      safetyTraining().then((res) => {
        this.safetyTrainingInfo = res.data || {}
      })
    },
    getWorkType() {
      workType()
        .then((res) => {
          const list = res.data || []
          this.workTypeList = list.reverse()
        })
        .catch((_) => {})
    },
    getTotalList() {
      totalList({ pageNum: 1, pageSize: 100 })
        .then((res) => {
          this.personnelTotalList = res.data.page.list || []
        })
        .catch((_) => {})
    },
  },
}
</script>

<style lang="less">
.zy-canvas-ring {
  &-number {
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    line-height: 24px;
  }
  &-des {
    font-weight: bold;
    font-size: 12px;
    color: #ffffff;
    line-height: 18px;
  }
}
.safe-table {
  margin-top: 24px;
}
.zy-pie-table {
  &-number {
    font-weight: bold;
    font-size: 20px;
    color: #ffffff;
    line-height: 24px;
  }
  &-des {
    font-weight: bold;
    font-size: 14px;
    color: #ffffff;
    line-height: 21px;
  }
}
</style>
