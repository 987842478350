import { get } from "@/utils/request"

// 获取项目目标
export function todayAttendance() {
  return get("/crm/personnel/todayAttendance")
}
// 在册人员情况
export function registeredNumber() {
  return get("/crm/personnel/registeredNumber")
}
// 出勤人数趋势
export function attendanceTrend(type) {
  return get("/crm/personnel/attendanceTrend", { type })
}
// 班组评分排名
export function teamScore() {
  return get("/index/screen/teamScore")
}

//安全培训
export function safetyTraining() {
  return get("/crm/personnel/safetyTraining")
}
// 工种分布
export function workType() {
  return get("/pms/workType/sun")
}
// 性别分布、工人年龄
export function ageGroup() {
  return get("/crm/personnel/ageGroup")
}
export function totalList(params) {
  return get("/crm/personnel/totalList", params)
}
