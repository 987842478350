<template>
  <div class="row-label-column">
    <div
      class="row-label"
      v-for="(row, index) in datas"
      :key="`${index}_row`"
    >
      <div
        class="row-label-item"
        v-for="(column, index) in row"
        :key="`${index}_item`"
      >
        <div class="row">
          <img
            class="row-label-item-img"
            :src="column.icon"
          />
          <div class="row-label-item-label">{{ column.name }}</div>
        </div>
        <div class="row end">
          <div class="row-label-item-number">{{ column.value }}</div>
          <div class="row-label-item-unit">{{ column.unit }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: Array,
  },
  computed: {
    datas() {
      const total_array = []
      let list = []
      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i]
        list.push(item)
        if (list.length >= 2) {
          total_array.push(list)
          list = []
        }
      }
      return total_array
    },
  },
}
</script>

<style lang="less" scoped>
.row-label {
  display: flex;
  flex-direction: row;
  & + & {
    margin-top: 10px;
  }
  &-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    padding: 10px 20px;
    width: 50%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    & + & {
      border-left: unset;
    }
    &-img {
      width: 18px;
      height: 18px;
    }
    &-label {
      font-size: 14px;
      color: #ffffff;
      width: 100%;
      margin-left: 15px;
      flex-shrink: 0;
    }
    &-number {
      font-weight: bold;
      font-size: 24px;
      color: #ffffff;
    }
    &-unit {
      font-size: 14px;
      color: #ffffff;
      margin-left: 5px;
    }
  }
}
.end {
  align-items: end;
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-shrink: 0;
}
</style>
