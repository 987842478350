<template>
  <div class="card">
    <div
      class="card-row"
      v-for="(row, row_index) in options"
      :key="`row_${row_index}`"
    >
      <div
        class="card-row-item"
        v-for="(config, item_index) in row"
        :key="`item_${item_index}`"
        :style="{ borderTop: `1px solid ${colors[(row_index + item_index) % 2]}` }"
      >
        <div
          class="card-row-item-title"
          :style="{ color: colors[(row_index + item_index) % 2] }"
        >
          {{ config.name }}
        </div>
        <div class="card-row-item-number">
          <zy-count-to :end-val="config.value"></zy-count-to>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ZyCountTo from "@/components/count-to.vue"
export default {
  components: { ZyCountTo },
  data() {
    return {
      colors: ["#66BBF9", "#63E881"],
    }
  },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    options() {
      const total_array = []
      let list = []
      for (let i = 0; i < this.list.length; i++) {
        const config = this.list[i]
        list.push(config)
        if (list.length >= 3) {
          total_array.push(list)
          list = []
        }
      }
      return total_array
    },
  },
}
</script>

<style lang="less" scoped>
.card {
  display: flex;
  flex-direction: column;
  margin-top: 10px;

  height: fit-content;
  &-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & + & {
      margin-top: 10px;
    }
    &-item {
      width: 244px;
      height: 96px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background: url("../../../assets/images/img_zcrey_bg.png");
      background-size: 100% 100%;

      &-title {
        font-size: 14px;
        line-height: 21px;
      }
      &-number {
        font-weight: bold;
        font-size: 26px;
        color: #ffffff;
        line-height: 33px;
        margin-top: 9px;
      }
    }
  }
}
</style>
