<template>
  <div class="sex-age">
    <div class="sex-age-title">性别分布</div>
    <div class="sex-age-sex">
      <div class="sex-age-sex-item">
        <img src="../../../assets/icons/icon_male.png" />
        <div>{{ ageGroupInfo.sexNum[0].sex }}</div>
        <div>
          <div>{{ ageGroupInfo.sexNum[0].num }}</div>
          <div>人</div>
        </div>
      </div>
      <div class="sex-age-sex-item">
        <img src="../../../assets/icons/icon_ratio_blue.png" />
        <div>占比</div>
        <div>
          <div>{{ ((ageGroupInfo.sexNum[0].num / ageGroupInfo.ageNum) * 100).toFixed(2) }}</div>
          <div>%</div>
        </div>
      </div>
    </div>
    <div class="sex-age-sex">
      <div class="sex-age-sex-item">
        <img src="../../../assets/icons/icon_female.png" />
        <div>{{ ageGroupInfo.sexNum[1].sex }}</div>
        <div>
          <div>{{ ageGroupInfo.sexNum[1].num }}</div>
          <div>人</div>
        </div>
      </div>
      <div class="sex-age-sex-item">
        <img src="../../../assets/icons/icon_ratio_red.png" />
        <div>占比</div>
        <div>
          <div>{{ ((ageGroupInfo.sexNum[1].num / ageGroupInfo.ageNum) * 100).toFixed(2) }}</div>
          <div>%</div>
        </div>
      </div>
    </div>
    <div class="sex-age-line"></div>
    <div class="sex-age-title">年龄分布</div>
    <zy-chart-bar
      :data="barList"
      :axis="barX"
      height="309px"
      direction="horizontal"
      :color="['#0360F5', '#FC4A66']"
    ></zy-chart-bar>
  </div>
</template>

<script>
import components from "@/view/jxyh/components"
import { ageGroup } from "@/jxyh/api/labor"
export default {
  ...components(["ZyChartBar"]),
  data() {
    return {
      ageGroupInfo: {
        sexNum: [{}, {}],
        man: [],
        woman: [],
      },
    }
  },
  computed: {
    barX() {
      const list = this.ageGroupInfo.man || []
      const barX = []
      for (let i = 0; i < list.length; i++) {
        const title = list[i].old
        barX.push(title)
      }
      return barX
    },
    barList() {
      const man = this.ageGroupInfo.man
      const woman = this.ageGroupInfo.woman
      const barList = [
        {
          name: "男",
          data: [],
        },
        {
          name: "女",
          data: [],
        },
      ]
      for (let i = 0; i < man.length; i++) {
        const manData = man[i].num
        const womanData = woman[i].num
        barList[0].data.push(manData)
        barList[1].data.push(womanData)
      }
      return barList
    },
  },
  created() {
    this.getAgeGroup()
  },
  methods: {
    getAgeGroup() {
      ageGroup()
        .then((res) => {
          this.ageGroupInfo = res.data || {}
        })
        .catch((_) => {})
    },
  },
}
</script>

<style lang="less" scoped>
.sex-age {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-title {
    padding: 10px 0 20px 0;
    font-weight: bold;
    font-size: 16px;
    color: #ffffff;
    line-height: 24px;
  }
  &-sex {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    border: 1px solid rgba(255, 255, 255, 0.1);
    &-item {
      border-left: 1px solid rgba(255, 255, 255, 0.1);
      padding: 0 20px;
      height: 48px;
      color: #ffffff;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      & > img {
        width: 18px;
        height: 18px;
      }
      & > div:nth-child(2) {
        font-size: 14px;
        color: #ffffff;
        line-height: 21px;
        width: 100%;
        margin-left: 15px;
      }
      & > div:nth-child(3) {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        & > div:nth-child(1) {
          font-weight: bold;
          font-size: 24px;
          color: #ffffff;
        }
        & > div:nth-child(2) {
          font-size: 14px;
          color: #ffffff;
          line-height: 21px;
          margin-left: 5px;
        }
      }
    }
    & + & {
      margin-top: 10px;
    }
  }
  &-line {
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    width: 100%;
    margin-top: 24px;
  }
}
</style>
